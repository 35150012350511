import React from "react";
import PropTypes from "prop-types";

const SecureField = ({
  fieldType,
  label,
  customClass,
  callback,
  children,
  style,
}) => {
  return (
    // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
    <div
      id={`${fieldType}-number-container`}
      className={customClass}
      style={style || {}}
      onClick={(e) => callback(e)}
    >
      <label htmlFor={`pci-${fieldType}-number`}>{label}</label>
      <div className="secure-field--input-container" style={{ height: "40px" }}>
        <div
          className="secure-field--input"
          id={`pci-${fieldType}-number`}
          style={{ width: fieldType === "cvv" ? 70 : "130%" }}
        />
        <div className="secure-field--actions">
          <span
            className={`secure-field--action secure-field--action__${fieldType}-icon`}
          >
            <span className={`secure-field--action-${fieldType}-wrap`}>
              {children}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

SecureField.propTypes = {
  fieldType: PropTypes.string,
  label: PropTypes.string,
  customClass: PropTypes.string,
  callback: PropTypes.func,
  children: PropTypes.object,
  style: PropTypes.object,
};

export default SecureField;
