import React from "react";
import { InlineUserInput } from "../components/inline-user-input.component";
import { HtmlMessageComponent } from "../components/html-message.component";
import { NoInputComponent } from "../components/step-components/NoInputComponent";
import { PrettyButtonGroup } from "../components/pretty-button-group.component";
import { GeoAutoComplete } from "../components/autocomplete";
import { ThumbDown, ThumbUpSharp } from "@mui/icons-material";
import { PROGRAM_TYPES } from "../constants";

export default function newCustomerOnboard(self) {
  return [
    {
      id: "new-customer-intro",
      message:
        "Hi my name is Kim... Who do I have the pleasure of speaking with?",
      trigger: "new-customer-collect-name",
    },
    {
      id: "new-customer-collect-name",
      component: <InlineUserInput placeholder={"John Smith"} />,
      dispatchMessage: (val) => {
        if (val && val !== "null") {
          self.setState({ customerName: val });
          // self.recordChatRecord({customerName: val});
        }
      },
      waitAction: true,
      trigger: "new-customer-welcome",
    },
    {
      id: "not-my-address",
      dispatchMessage: () => {
        self.setState({
          clickedNoFromContactForm: true,
        });
      },
      component: <HtmlMessageComponent />,
      inputAttributes: {
        html: `<div>
        Okay well then let me ask you some questions to make sure we get you
        the most accurate quote possible.
      </div>`,
      },
      asMessage: true,
      trigger: "no-address-phone-ask",
    },
    {
      id: "new-customer-welcome",
      message:
        "Wonderful {previousValue}!  Thanks so much for interacting with our bot",
      trigger: "new-customer-address-ask",
    },
    {
      id: "new-customer-address-ask",
      message:
        "What is the street address of the property which you would like a quote?",
      trigger: "new-customer-address-collect",
    },
    {
      id: "new-customer-address-collect",
      user: true,
      placeholder: "Enter Street Address",
      trigger: "new-customer-city-state-ask",
    },
    {
      id: "new-customer-city-state-ask",
      message: "What is the city and state of the property?",
      trigger: "new-customer-city-state-collect",
    },
    {
      id: "new-customer-city-state-collect",
      user: true,
      placeholder: "Enter City, State",
      trigger: "new-customer-lookup-start",
    },
    {
      id: "new-customer-lookup-start",
      message:
        "Perfect!  We are now going to attempt to collect data on your property...",
      trigger: "get-calculating-step",
    },
    {
      id: "getting-started",
      message: () => {
        if (self.crmSolution === "servman") {
          return "Wonderful! To schedule your service, we will need to collect some information from you.";
        }
        return "Wonderful!";
      },
      trigger: "new-customer-phone-ask-enter",
    },
    {
      id: "new-customer-phone-ask-enter",
      message: () => {
        if (self.crmSolution === "servman") {
          return "Please enter your phone number below.";
        }
        return "To start your Instant Quote please enter your complete phone number.";
      },
      trigger: "new-customer-phone-collect",
    },
    {
      id: "no-address-phone-ask",
      message:
        "I can look up your Instant Quote please enter your complete phone number.",
      trigger: "new-customer-phone-collect",
    },
    {
      id: "new-customer-phone-collect",
      asMessage: false,
      component: (
        <InlineUserInput
          placeholder={"Enter 10 Digit Phone Number"}
          type={"tel"}
          name={"phone"}
          maxLength={11}
          customButton={true}
          defaultInputMessage={"Send"}
          validator={(value) => {
            const check = `${value}`.length;

            // biome-ignore lint/suspicious/noGlobalIsNan: Making this Global.Nan might give unexpected results
            if (isNaN(value) || (check !== 10 && check !== 11)) {
              return "Phone number must be 10 or 11 digits";
            }
            return true;
          }}
        />
      ),
      waitAction: true,
      sendUserMessage: true,
      dispatchMessage: (value) => {
        if (value && value !== "null") {
          self.setState({
            phonenumber: value,
          });
        }
      },
      trigger: "ask-new-customer-address",
      // trigger: "new-customer-zip-ask"
    },
    {
      id: "ask-new-customer-address",
      message: "Please enter your address.",
      trigger: () => {
        return "collect-new-customer-address";
      },
    },
    {
      id: "collect-new-customer-address",
      component: <GeoAutoComplete dashboardApi={self.dashboardApi} />,
      waitAction: true,
      asMessage: false,
      trigger: () => {
        return "process-new-customer-address";
      },
    },
    {
      id: "process-new-customer-address",
      component: (
        <NoInputComponent
          dispatchMessage={async (previousValue) => {
            const address = previousValue.value;
            if (typeof address !== "undefined" && address !== null) {
              const customerState = {
                customerAddress: `${address.houseNumber} ${address.street}`,
                customerCity: address.city,
                customerState: address.stateCode,
                customerZip: address.postalCode,
              };
              await self.setState(customerState);
            }
          }}
        />
      ),
      asMessage: false,
      waitAction: true,
      trigger: () => {
        const value = self.state.customerZip.toLowerCase();
        const hasNull = self.zipCodes.filter((i) => i === "null").length;
        const zipcodeMap = self.zipCodes
          .filter((i) => i !== "null" && i !== "")
          .map((i) => `${i}`.toLowerCase())
          .filter((i) => {
            return `${value}`.indexOf(i) !== -1;
          });

        if (!zipcodeMap.length && !hasNull) {
          return "zip-not-serviced";
        }
        const donttax =
          self?.dontTaxZipCodes && Array.isArray(self.dontTaxZipCodes)
            ? self.dontTaxZipCodes
            : [];

        if (value && donttax.includes(value)) {
          self.serviceTax = 0.0;
          self.lookupZipCodeTax = false;
        } else {
          if (self.lookupZipCodeTax) {
            self.lookupPostalTaxRate(value, null);
          }
        }

        if (self.crmSolution !== "real-green") {
          return "ask-address-line2-needed";
        }
        return "looking-up-property";
      },
    },
    {
      id: "ask-address-line2-needed",
      message: "Is there an apartment, unit or suite number?",
      trigger: "collect-address-line2-needed",
    },
    {
      id: "collect-address-line2-needed",
      component: (
        <PrettyButtonGroup
          step={this}
          self={self}
          buttons={[
            {
              value: "Yes",
              label: "Yes",
              icon: <ThumbUpSharp />,
              style: {
                backgroundColor: "white",
                color: "#1565C0",
                borderRadius: "24px",
                padding: "10px 20px",
                fontSize: "16px",
                minWidth: "150px",
                marginRight: "20px",
              },
            },
            {
              value: "No",
              label: "No",
              icon: <ThumbDown />,
              style: {
                backgroundColor: "#1565C0",
                color: "white",
                borderRadius: "24px",
                padding: "10px 20px",
                fontSize: "16px",
                minWidth: "150px",
                marginRight: "20px",
              },
            },
          ]}
        />
      ),
      asMessage: false,
      waitAction: true,
      setVariable: "Apartment.Suite.Unit.Number",
      trigger: () => {
        if (self.answers["Apartment.Suite.Unit.Number"] === "Yes") {
          return "ask-address-line2";
        }
        return "looking-up-property";
      },
    },
    {
      id: "ask-address-line2",
      message: "Please enter the apartment, unit or suite number",
      trigger: "collect-address-line2",
    },
    {
      id: "collect-address-line2",
      component: (
        <InlineUserInput
          placeholder={"Apartment, Unit or Suite Number"}
          type={"text"}
          customButton={true}
          defaultInputMessage={"Send"}
          validator={(value) => {
            if (value.trim() === "") {
              return "Apartment, Unit or Suite Number is Required";
            }
            return true;
          }}
        />
      ),

      waitAction: true,
      sendUserMessage: true,
      dispatchMessage: (val) => {
        if (val && val !== "null") {
          self.setState({ customerAddress2: val });
          // self.recordChatRecord({customerName: val});
        }
      },
      trigger: "looking-up-property",
    },
    {
      id: "new-customer-address-ask",
      message: `Great! We have many customers in {previousValue}.
      
          Please enter your street address
           
          
          Example. (7890 Main St) `,
      trigger: "collect-new-customer-address",
    },
    {
      id: "looking-up-property",
      component: (
        <NoInputComponent
          dispatchMessage={async () =>
            await self.lookupEstimateFromSources({
              customerAddress: self.state.customerAddress,
            })
          }
          disappearAfter={true}
          asHTML={true}
          showLoading={true}
          message={`<div style="color: #ccc; font-size: 18px; margin-top:10px;">We are looking up information on your property... please wait.</div>`}
        />
      ),
      asMessage: false,
      waitAction: true,
      trigger: () => {
        if (self.hideArielAndStreetView) {
          return "ask-email";
        }
        const totalLotSize = self.state.totalLotSize;
        if (self.sqftEstimateSource === "measur-it") {
          return "show-customer-aerial";
        }
        if (totalLotSize || self.botType === "pest") {
          return "show-customer-aerial";
        }
        return "well-thanks-anyway";
      },
    },
    {
      id: "square-footage-cant-find",
      message:
        "We couldn't find any information on your internal square footage from our sources.",
      trigger: "manual-square-footage-ask",
    },
    {
      id: "manual-square-footage-ask",
      message: "Please enter your house's square footage below.",
      trigger: "enter-manual-square-footage",
    },
    {
      id: "enter-manual-square-footage",
      component: (
        <InlineUserInput
          placeholder={"Internal Square Footage"}
          dispatchMessage={async (value) => {
            self.setState((prev) => {
              return {
                ...prev,
                totalLotSize: Number.parseFloat(value),
                adjustedLotSize: Number.parseFloat(value),
              };
            });
          }}
        />
      ),
      waitAction: true,
      trigger: (value) => {
        if (
          Number.parseFloat(value) < self.companyMinQuotableSize ||
          Number.parseFloat(value) > self.companyMaxQuotableSize
        ) {
          return "well-thanks-anyway";
        }
        const { selectedPrograms, steps } = self.state;
        const programsToCheck = [
          PROGRAM_TYPES.IRRIGATION_SERVICES,
          PROGRAM_TYPES.PET_WASTE,
          PROGRAM_TYPES.COMMERCIAL_CLEANING,
          PROGRAM_TYPES.RESIDENTIAL_CLEANING,
        ];

        if (
          programsToCheck.some((program) => selectedPrograms.includes(program))
        ) {
          return self.startServiceQuestions();
        }

        const questions = selectedPrograms
          .map((i) => {
            const entry = `${self.getSericeEntryId(i)}-entry`;
            const item = steps.find((t) => t.id === entry);
            return item;
          })
          .filter((p) => p);
        if (self.preModalQuestions.length) {
          return "pre-modal-entry";
        }

        if (questions.length) {
          return "ask-quote-now";
        }
        self.updateCalculatingStep();
        return "no-questions-open-quote";
      },
    },
    {
      id: "congrats-sale",
      message: "Congratulations!",
      trigger: () => {
        self.purchaseMade = true;
        return "sales-party";
      },
    },
    {
      id: "sales-party",
      component: <HtmlMessageComponent />,
      inputAttributes: {
        html: "<img src='/assets/images/party.gif' alt='congrats on sale' width='100%' />",
      },
      trigger: "service-questions-entry",
    },
    {
      id: "zip-not-serviced",
      message:
        "It appears we cannot quote your property. Do you have any comments for our team?",
      trigger: "get-comments",
    },
    {
      id: "sqft-too-big",
      message:
        "After reviewing your property, we feel it would be best for us to quote your property by phone or in-person.  Do you have any questions for our team?",
      trigger: "get-comments",
    },
  ];
}
