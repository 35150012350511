import React from "react";
import { HtmlMessageComponent } from "../components/html-message.component";
import { InlineUserInput } from "../components/inline-user-input.component";
import { RedirectMessageComponent } from "../components/redirect-message.component";
import { NoInputComponent } from "../components/step-components/NoInputComponent";
import Dropdown from "../components/inputs/Dropdown";

import {
  ThumbDownSharp,
  ThumbUpSharp,
  OfflineBolt,
  Search,
} from "@mui/icons-material";
import { PrettyButtonGroup } from "../components/pretty-button-group.component";
import { toTitleCase } from "../utils/utils";

import { FirstLastNameCombo } from "../components/first-last-name-combo";
import { PROGRAM_TYPES } from "../constants";

function validateEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
function previousQuoteChecks(self) {
  if (self.crmSolution === "real-green" && self.state.convertedSale) {
    if (self.crmPortalUrl) {
      self.redirectToPortal = true;
      return {
        trigger: "message-redirect-to-portal",
        message: "...",
      };
    }
    if (self._bot?.current) {
      return {
        trigger: "sqft-too-big",
        message: "...",
      };
    }
  }
  if (self.state.priceBlocks.length) {
    return {
      message: "You're all set! Let's open your previous quote...",
      trigger: "ask-would-you-like-to-reopen",
    };
  }

  if (!self.state.selectedPrograms.length) {
    return {
      message:
        "Looks like you have not selected any services. Let's go ahead and do that.",
      trigger: "intro-program",
    };
  }

  const hasIrrigation = !!self.state.selectedPrograms.find(
    (i) => i === PROGRAM_TYPES.IRRIGATION_SERVICES,
  );
  const hasBinCleaning = self.state.selectedPrograms.find(
    (i) => i === PROGRAM_TYPES.BIN_CLEANING,
  );
  const hasPetWaste = !!self.state.selectedPrograms.find(
    (i) => i === PROGRAM_TYPES.PET_WASTE,
  );

  const hasCommercialCleaning = !!self.state.selectedPrograms.find(
    (i) => i === PROGRAM_TYPES.COMMERCIAL_CLEANING,
  );
  const hasResidentialCleaning = !!self.state.selectedPrograms.find(
    (i) => i === PROGRAM_TYPES.RESIDENTIAL_CLEANING,
  );

  const hasSqftServices = !!self.state.selectedPrograms.filter(
    (i) =>
      i !== PROGRAM_TYPES.IRRIGATION_SERVICES &&
      i !== PROGRAM_TYPES.BIN_CLEANING &&
      i !== PROGRAM_TYPES.PET_WASTE,
  ).length;

  if (
    self.sqftEstimateSource === "measur-it" &&
    hasSqftServices &&
    !self.state.adjustedLotSize
  ) {
    return {
      message:
        "Looks like you left before measuring your property.  Let's go ahead and do that.",
      trigger: "lets-measure-your-lawn-together",
    };
  }

  if (
    hasBinCleaning ||
    hasIrrigation ||
    hasPetWaste ||
    hasCommercialCleaning ||
    hasResidentialCleaning
  ) {
    const trigger = self.startServiceQuestions();
    return {
      message: "Let's get some more info on your selected programs...",
      trigger: trigger,
    };
  }

  if (self.preModalQuestions.length) {
    const questions = [];
    // biome-ignore lint/complexity/noForEach: ignore this for now
    self.preModalQuestions.forEach((question) => {
      // biome-ignore lint/complexity/noForEach: ignore this for now
      question.probing_questions.forEach((probe) => {
        if (probe.setVariable) {
          questions.push(probe.setVariable);
        }
      });
    });
    const notAnswered = questions.find(
      // biome-ignore lint/suspicious/noPrototypeBuiltins: <explanation>
      (question) => !self.state.answers.hasOwnProperty(question),
    );
    if (notAnswered) {
      return {
        message: "There are still some questions we need to ask you...",
        trigger: "pre-modal-entry",
      };
    }
  }

  if (!self.state.priceBlocks.length) {
    return {
      message: "We have everything needed to build your quote!",
      trigger: "get-quote-now",
    };
  }
}
export default function welcomeSteps(self) {
  return [
    {
      id: "1",
      inputAttributes: {
        html: self.greeting
          ? self.greeting
          : `Welcome to <b>${self.companyName}</b> Instant Quote™
        <br><br>
        📏 <b>We Can Measure Your Property</b><br>
        👨🏻‍🔬 <b>Analyze Your Service Area</b><br>
        👆🏻 <b>Tailor A Custom Program</b><br>
        📝 <b>Deliver An Instant Quote</b><br>
        <br><br>
        Let's Get Started Below To Begin👇
        `,
      },
      component: <HtmlMessageComponent />,
      asMessage: true,
      trigger: () => {
        if (self.state.phonenumber) {
          return "welcomeback";
        }
        return "2";
      },
    },
    {
      id: "check-local-storage",
      component: (
        <NoInputComponent
          showLoading={true}
          disappearAfter={true}
          dispatchMessage={async () => {
            if (!self.state.chatId) {
              const status = await self.checkAlreadyQuoted();

              if (status.quoteId) {
                self.setState(
                  (prevState) => {
                    return {
                      ...prevState,
                      ...status,
                      ...status.customer,
                      chatId: status.quoteId,
                    };
                  },
                  () => {
                    if (status.priceBlocks.length) {
                      self.setPriceBlocks(status.priceBlocks);
                    }
                  },
                );
                const {
                  message: previousQuoteMessage,
                  trigger: previousQuoteTrigger,
                } = previousQuoteChecks({
                  ...self,
                  state: { ...self.state, ...status, ...status.customer },
                });
                if (previousQuoteMessage && previousQuoteTrigger) {
                  self.setState((prevState) => {
                    return {
                      ...prevState,
                      previousQuoteMessage,
                      previousQuoteTrigger,
                    };
                  });
                }
              }
            }
          }}
          asHTML={true}
          message={
            self.crmSolution === "servman"
              ? "<div/>"
              : "Checking for previous quote..."
          }
        />
      ),
      asMessage: false,
      waitAction: true,
      trigger: () => {
        if (self.state.quoteId) {
          if (self.crmSolution === "servman") {
            return "emergency";
          }
          return "looks-like-you-quoted";
        }

        return "could-not-find-you";
      },
    },
    {
      id: "looks-like-you-quoted",
      delay: 3000,
      message: "It looks like you've quoted through our system already.",
      trigger: "show-previous-quote-details",
    },
    {
      id: "show-previous-quote-details",
      delay: 3000,
      message: () => {
        return self.state.previousQuoteMessage;
      },
      trigger: (value) => {
        return self.state.previousQuoteTrigger;
      },
    },
    {
      id: "ask-would-you-like-to-reopen",
      delay: 3000,
      message: "Re-Opening Your Quote",
      trigger: () => {
        if (self._modal.current) {
          self._modal.current.handleOpen();
        }
      },
    },

    {
      id: "get-reopen-quote",
      options: [
        {
          value: "yes",
          label: "Yes",
          trigger: "confirm-reopen-quote",
          text: "👍",
        },
        { value: "no", label: "No", trigger: "not-my-address", text: "👎" },
      ],
      optionType: "default",
    },

    {
      id: "confirm-reopen-quote",
      message: () => {
        alert("it");
        if (self._modal.current) {
          self._modal.current.handleOpen();
        }
        return "Great Reopening Now";
      },
      trigger: "",
    },

    {
      id: "2",

      component: (
        <PrettyButtonGroup
          step={this}
          self={self}
          buttons={
            self.crmSolution === "servman"
              ? [
                  {
                    label: self.gettingStartedText || "Let's Get Started",
                    value: "Let's Get Started",
                    // icon: <OfflineBolt style={{ color: "yellow" }} />
                  },
                ]
              : [
                  {
                    label: "Let's Get Started",
                    value: "Let's Get Started",
                    icon: <OfflineBolt style={{ color: "yellow" }} />,
                  },
                  {
                    label: "Learn More",
                    value: "Learn More",
                    icon: <Search />,
                  },
                ]
          }
        />
      ),
      asMessage: false,
      waitAction: true,
      trigger: ({ previousValue, steps }) => {
        previousValue = self.answers["Greeting.Selection"];
        if (previousValue === "Let's Get Started") {
          return "getting-started";
        }
        return "learn-more";
      },
      setVariable: "Greeting.Selection",
    },
    {
      id: "welcomeback",
      message: () => {
        return `${self.salutation} ${
          self.state.customerName.trim().split(" ")[0]
        }!`;
      },
      trigger: () => {
        if (self.redirectToPortal) {
          return "message-redirect-to-portal";
        }

        if (self.state.customerLat !== 0) {
          return "show-customer-aerial";
        }
        const { noPII, adjustedLotSize } = self.state;
        const max = self.companyMaxQuotableSize;
        const min = self.companyMinQuotableSize;
        if (adjustedLotSize > max || adjustedLotSize < min) {
          return "sqft-too-big";
        }
        if (noPII) {
          return "ask-email";
        }
        const hasNull = self.zipCodes.filter((i) => i === "null").length;
        const zipcodeMap = self.zipCodes
          .filter((i) => i !== "null" && i !== "")
          .map((i) => `${i}`.toLowerCase())
          .filter((i) => {
            return `${self.state.customerZip}`.toLowerCase().indexOf(i) !== -1;
          });

        if (!zipcodeMap.length && !hasNull) {
          return "zip-not-serviced";
        }

        return "customer-confirm-address";
      },
    },

    {
      id: "show-customer-aerial",
      inputAttributes: {
        html: () => {
          return `<img width="100%" src="${self.state.aerialImg}" />`;
        },
      },
      component: <HtmlMessageComponent />,
      asMessage: false,
      trigger: () => {
        if (self.state.streetImg) {
          return "show-customer-streetview";
        }
        const { noPII, adjustedLotSize } = self.state;
        const max = self.companyMaxQuotableSize;
        const min = self.companyMinQuotableSize;
        if (
          (adjustedLotSize > max || adjustedLotSize < min) &&
          self.sqftEstimateSource !== "measur-it"
        ) {
          return "sqft-too-big";
        }
        if (noPII) {
          return "ask-email";
        }
        const hasNull = self.zipCodes.filter((i) => i === "null").length;
        const zipcodeMap = self.zipCodes
          .filter((i) => i !== "null" && i !== "")
          .map((i) => `${i}`.toLowerCase())
          .filter((i) => {
            return `${self.state.customerZip}`.toLowerCase().indexOf(i) !== -1;
          });

        if (!zipcodeMap.length && !hasNull) {
          return "zip-not-serviced";
        }
        return "customer-confirm-address";
      },
    },
    {
      id: "show-customer-streetview",
      inputAttributes: {
        html: () => {
          return `<img width="100%" src="${self.state.streetImg}" />`;
        },
      },
      component: <HtmlMessageComponent />,
      asMessage: false,
      trigger: () => {
        const { noPII, adjustedLotSize } = self.state;
        const max = self.companyMaxQuotableSize;
        const min = self.companyMinQuotableSize;
        if (
          (adjustedLotSize > max || adjustedLotSize < min) &&
          self.sqftEstimateSource !== "measur-it"
        ) {
          return "sqft-too-big";
        }
        if (noPII) {
          return "ask-email";
        }
        const hasNull = self.zipCodes.filter((i) => i === "null").length;

        const zipcodeMap = self.zipCodes
          .filter((i) => i !== "null" && i !== "")
          .map((i) => `${i}`.toLowerCase())
          .filter((i) => {
            return `${self.state.customerZip}`.toLowerCase().indexOf(i) !== -1;
          });

        if (!zipcodeMap.length && !hasNull) {
          return "zip-not-serviced";
        }
        return "customer-confirm-address";
      },
    },

    {
      id: "customer-confirm-address",
      inputAttributes: {
        html: () => {
          return self.crmSolution === "servman"
            ? `Are you looking for services at the below address? <br><br><b>${self.state.customerAddress}</b>`
            : `You are looking for a quote for your home located at <br><br><b>${self.state.customerAddress}</b><br><br>Correct?`;
        },
      },
      component: <HtmlMessageComponent />,
      asMessage: true,
      trigger: "show-pretty-yes-no",
    },
    {
      id: "show-pretty-yes-no",
      component: (
        <PrettyButtonGroup
          buttons={[
            {
              label: "Yes",
              value: "yes",
              icon: <ThumbUpSharp />,
              style: {
                backgroundColor: "#1565C0",
                color: "white",
                borderRadius: "24px",
                padding: "10px 20px",
                fontSize: "16px",
                minWidth: "150px",
                marginRight: "20px",
              },
            },
            {
              label: "No",
              value: "no",
              icon: <ThumbDownSharp />,
              style: {
                backgroundColor: "white",
                color: "#1565C0",
                borderRadius: "24px",
                padding: "10px 20px",
                fontSize: "16px",
                minWidth: "150px",
              },
            },
          ]}
        />
      ),
      asMessage: false,
      waitAction: true,
      trigger: "verify-yn-decision",
    },
    {
      id: "verify-yn-decision",
      message: ({ previousValue, steps }) => {
        self.correct = previousValue;

        if (previousValue === "yes") {
          return "Great! Let's get started.";
        }
        return "No problem. Let's try again";
      },
      trigger: ({ previousValue, steps }) => {
        if (self.correct === "yes") {
          return self.crmSolution === "real-green" &&
            self.areMarketingQuestionsActivated &&
            self.marketingSourceInquiryPrompt &&
            self.availableMarketingSources?.length
            ? "show-marketing-source-inquiry-prompt"
            : "check-local-storage";
        }
        return "not-my-address";
      },
    },
    {
      id: "customer-confirm-address-options",
      options: [
        {
          value: "yes",
          label: "Yes",
          trigger:
            self.crmSolution === "real-green" &&
            self.areMarketingQuestionsActivated &&
            self.marketingSourceInquiryPrompt &&
            self.availableMarketingSources?.length
              ? "show-marketing-source-inquiry-prompt"
              : "check-local-storage",
          text: "👍",
        },
        { value: "no", label: "No", trigger: "not-my-address", text: "👎" },
      ],
      optionType: "default",
    },
    {
      id: "ask-email",
      message: `Please provide your email address.

    (Example: jsmith@gmail.com)`,
      trigger: "get-email",
    },
    {
      id: "get-email",
      component: (
        <InlineUserInput
          placeholder={"jsmith@gmail.com"}
          type={"email"}
          validator={(value) => {
            const valid = validateEmail(value);
            if (!valid) {
              return "Please enter a valid email address";
            }
            return true;
          }}
        />
      ),
      waitAction: true,
      sendUserMessage: true,
      dispatchMessage: (value) => {
        if (value && value !== "null") {
          self.setState({ customerEmail: value });
        }
      },
      trigger: "display-welcome-message-",
    },
    {
      id: "display-welcome-message-",
      message: "Please provide your first and last name.",
      trigger: "get-first-last-name",
    },
    {
      id: "ask-first-name",
      message: "Please enter your first and last name.",
      trigger: "get-first-last-name",
    },
    {
      id: "get-first-last-name",
      component: (
        <FirstLastNameCombo
          dispatchMessage={async (value) => {
            if (value && value !== "null") {
              await self.setState({
                customerFirstName: toTitleCase(value.firstName),
                customerLastName: toTitleCase(value.lastName),
              });
            }
          }}
        />
      ),
      waitAction: true,
      sendUserMessage: true,

      trigger: () => {
        return self.crmSolution === "real-green" &&
          self.areMarketingQuestionsActivated &&
          self.marketingSourceInquiryPrompt &&
          self.availableMarketingSources?.length
          ? "show-marketing-source-inquiry-prompt"
          : "check-local-storage";
      },
    },
    {
      id: "could-not-find-you",
      component: (
        <NoInputComponent
          disappearAfter={true}
          showLoading={true}
          dispatchMessage={async () => {
            await new Promise((res) => {
              setTimeout(() => {
                res(true);
              }, 500);
            });
          }}
          asHTML={true}
          message={`<div style="display:flex; flex-direction: column; justify-content: center; align-items: flex-start; ">
          <div style="color: #ccc; font-size: 18px; margin-top:10px;"><i>Checking to see if we’ve serviced you before...</i></div>
          <div style="color: #ccc; font-size: 18px; margin-top:10px;"><i>Searching our system...</i></div>
        </div>`}
        />
      ),
      asMessage: false,
      waitAction: true,
      trigger: "start-quote",
    },

    {
      id: "start-quote-greeting",
      message: ({ previousValue, steps }) => {
        if (previousValue) {
          const firstName = previousValue.split(" ")[0];
          return `Great to meet you, ${firstName}!`;
        }
        return "Great to meet you!";
      },
      trigger: "start-quote",
    },
    {
      id: "start-quote",
      component: (
        <NoInputComponent
          showLoading={true}
          disappearAfter={true}
          dispatchMessage={async () => {
            // if quote has already started, don't start again
            if (!self.state.chatId) {
              const quote_id = await self.startChatRecord();
              await self.setState({ chatId: quote_id });
            }
          }}
          asHTML={true}
          message={
            self.crmSolution === "servman"
              ? `<div style="color: #ccc; font-size: 18px; margin-top:13px;">Please wait while we get started<div>`
              : `<div style="color: #ccc; font-size: 18px; margin-top:10px;">Please wait while I start your quote...</div>`
          }
        />
      ),
      asMessage: false,
      waitAction: true,
      trigger: () => {
        if (self.crmSolution === "servman") {
          return "ask-is-this-an-emergency";
        }
        return "company-value-add-entry";
      },
    },
    {
      id: "measure-lawn",
      message:
        "Thank you. While I’m measuring your property, I just need you to answer some questions so I can build the best program…",
      trigger: "estimate-tada",
    },
    {
      id: "estimate-tada",
      component: <HtmlMessageComponent />,
      inputAttributes: {
        html: "<b>I finished measuring your property (ta-da!)</b>",
      },
      asMessage: true,
      trigger: () => {
        if (
          self.state.adjustedLotSize === 0 ||
          // biome-ignore lint/suspicious/noGlobalIsNan: <explanation>
          isNaN(self.state.adjustedLotSize) ||
          typeof self.state.adjustedLotSize === "undefined"
        ) {
          return "well-thanks-anyway";
        }
        if (self.state.measuritImg) {
          return "display-measurit-image";
        }
        self.recordEstimate(self.state.adjustedLotSize, "sqft", null);
        return "display-estimate";
      },
    },
    {
      id: "display-measurit-image",
      component: <HtmlMessageComponent />,
      inputAttributes: { html: `<img src=${self.state.measuritImg} />` },
      asMessage: true,
      trigger: "you-colored-lawn-size",
    },
    {
      id: "display-estimate",
      message: () => {
        return `Making sure not to include the house or driveway, we measured your property to be ${self.state.adjustedLotSize} sq ft.`;
      },
      trigger: "intro-program",
    },
    {
      id: "show-marketing-source-inquiry-prompt",
      message: () => {
        return (
          self.marketingSourceInquiryPrompt || "How did you hear about us?"
        );
      },
      trigger: "collect-marketing-source",
    },
    {
      id: "collect-marketing-source",
      asMessage: false,
      component: (
        <Dropdown
          id="marketing-source-dropdown"
          setValue={(source) => {
            if (source && source !== "null") {
              const sourceData = {
                code: source.value.value,
                description: source.value.label,
              };
              self.setState((prev) => {
                return {
                  ...prev,
                  source: sourceData,
                };
              });
            }
          }}
          selectOptions={self.availableMarketingSources?.map((source) => ({
            value: source.code,
            label: source.description,
          }))}
        />
      ),
      waitAction: true,
      sendUserMessage: true,
      trigger: ({ value }) => {
        const selectedSource = (value || "").toLowerCase();
        if (selectedSource === "referral") {
          return "show-referrer-name-inquiry-prompt";
        }
        if (selectedSource === "other") {
          return "show-other-source-inquiry-prompt";
        }
        return "check-local-storage";
      },
    },
    {
      id: "show-other-source-inquiry-prompt",
      message: "Please elaborate on how you heard about us",
      trigger: "enter-other-source-value",
    },
    {
      id: "enter-other-source-value",
      component: (
        <InlineUserInput
          placeholder={"Please elaborate"}
          maxLength={300}
          validator={(value) => {
            const trimmedValue = `${value}`.trim();
            if (!trimmedValue) {
              return "This field is required.";
            }
            if (trimmedValue.length > 300) {
              return "Please enter a value not exceeding 300 characters";
            }
            return true;
          }}
          dispatchMessage={async (value) => {
            self.setState((prev) => {
              return {
                ...prev,
                additionalSourceInfo: value,
              };
            });
          }}
        />
      ),
      waitAction: true,
      trigger: () => {
        return "check-local-storage";
      },
    },
    {
      id: "show-referrer-name-inquiry-prompt",
      message: "Please provide the name of the referring party",
      trigger: "enter-referrer-name",
    },
    {
      id: "enter-referrer-name",
      component: (
        <InlineUserInput
          placeholder={"Name of Referrer"}
          maxLength={30}
          validator={(value) => {
            const trimmedValue = `${value}`.trim();
            if (!trimmedValue) {
              return "This field is required.";
            }
            if (trimmedValue.length > 30) {
              return "Please enter a value not exceeding 30 characters";
            }
            return true;
          }}
          dispatchMessage={async (value) => {
            self.setState((prev) => {
              return {
                ...prev,
                referrer: value,
              };
            });
          }}
        />
      ),
      waitAction: true,
      trigger: () => {
        return "check-local-storage";
      },
    },
    {
      id: "company-value-add-entry",

      message: () =>
        self.companyValueDescription ||
        "COMPANY VALUE ADD: this step should have been replaced via api",
      trigger: "company-value-add-image",
    },
    {
      id: "company-value-add-image",
      asMessage: true,
      waitAction: true,
      component: (
        <NoInputComponent
          asHTML={true}
          message={`<img src="${self.companyValueAddImage}" width="100%" />`}
        />
      ),
      trigger: () => {
        if (self.crmSolution === "servman") {
          return "ask-is-this-an-emergency";
        }
        if (
          self.sqftEstimateSource === "measur-it" ||
          self.sqftEstimateSource === "home"
        ) {
          return "intro-program";
        }
        return "estimate-tada";
      },
    },

    {
      id: "match-program-message",
      message: "In order to match you with the perfect program",
      trigger: "intro-program",
    },
    {
      id: "measur-it-okay-great",
      message: "Okay great…",
      trigger: "lets-measure-your-lawn-together",
    },
    {
      id: "home-okay-great",
      message: "Okay great...",
      trigger: () => {
        if (self.state.totalLotSize) {
          return "show-pest-square-footage";
        }
        return "square-footage-cant-find";
      },
    },
    {
      id: "show-pest-square-footage",
      message: () => {
        return `The data we collected from our sources shows your internal square footage to be ${self.state.adjustedLotSize}`;
      },
      trigger: () => {
        if (
          self.state.adjustedLotSize < self.companyMinQuotableSize ||
          self.state.adjustedLotSize > self.companyMaxQuotableSize
        ) {
          return "well-thanks-anyway";
        }
        const { selectedPrograms, steps } = self.state;

        const programsToCheck = [
          PROGRAM_TYPES.IRRIGATION_SERVICES,
          PROGRAM_TYPES.PET_WASTE,
          PROGRAM_TYPES.COMMERCIAL_CLEANING,
          PROGRAM_TYPES.RESIDENTIAL_CLEANING,
        ];

        if (
          programsToCheck.some((program) => selectedPrograms.includes(program))
        ) {
          return self.startServiceQuestions();
        }

        const questions = selectedPrograms
          .map((i) => {
            const entry = `${self.getSericeEntryId(i)}-entry`;
            const item = steps.find((t) => t.id === entry);
            return item;
          })
          .filter((p) => p);

        if (self.preModalQuestions.length) {
          return "pre-modal-entry";
        }

        if (questions.length) {
          return "ask-quote-now";
        }
        self.updateCalculatingStep();
        return "no-questions-open-quote";
      },
    },
    { id: "now-opening-quote", message: "Now opening your quote", end: true },
    {
      id: "lets-measure-your-lawn-together",
      message: "Now let’s go ahead and measure your property together!",
      trigger: "now-opening-measur-it",
    },
    {
      id: "now-opening-measur-it",
      message: () => {
        const s = self;
        setTimeout(() => {
          s.setState({ estimateModalOpened: true });
        }, 3000);

        let action = "color";
        if (self.measurementMode === "perimeter") {
          action = "outline";
        }

        return `Opening our Measur.it technology… ${action} in the areas that you want serviced this season.`;
      },
      end: true,
    },

    {
      id: "you-colored-lawn-size",
      message: () => {
        let label = "sq ft";
        let action = "colored";
        if (self.measurementMode === "perimeter") {
          action = "outlined";
          if (self.isMetric) {
            label = "linear m";
          } else {
            label = "linear ft";
          }
        } else {
          if (self.isMetric) {
            label = "sq m";
          } else {
            label = "sq ft";
          }
        }
        // self.recordChatRecord({adjustedLotSize: self.adjustedLotSize})
        return `Awesome... Looks like you ${action} in ${self.adjustedLotSize} ${label} to be serviced.`;
      },
      trigger: () => {
        if (
          self.adjustedLotSize < self.companyMinQuotableSize ||
          self.adjustedLotSize > self.companyMaxQuotableSize
        ) {
          return "well-thanks-anyway";
        }
        const { selectedPrograms, steps } = self.state;

        if (
          selectedPrograms.indexOf("Irrigation Services") !== -1 ||
          selectedPrograms.indexOf("Pet Waste") !== -1
        ) {
          const dest = self.startServiceQuestions();
          return dest;
        }

        const questions = selectedPrograms
          .map((i) => {
            const entry = `${self.getSericeEntryId(i)}-entry`;
            const item = steps.find((t) => t.id === entry);
            return item;
          })
          .filter((p) => p);

        if (self.preModalQuestions.length) {
          return "pre-modal-entry";
        }

        if (questions.length) {
          return "ask-quote-now";
        }
        self.updateCalculatingStep();
        return "no-questions-open-quote";
      },
    },
    {
      id: "no-questions-open-quote",
      message: "We will now recommend the best possible programs.",
      trigger: "get-calculating-step",
    },
    {
      id: "confirm-lot-size",
      options: [
        { value: "Yes", label: "Yes", trigger: "intro-program", text: "👍" },
        { value: "No", label: "No", trigger: "sqft-slider", text: "👎" },
      ],
      optionType: "default",
    },

    {
      id: "ready-question",
      message: "Ready to get your quote?",
      trigger: "do-it",
    },

    {
      id: "do-it",
      component: (
        <PrettyButtonGroup
          buttons={[
            {
              label: "Yes Let's Do It",
              value: "Yes Let's Do It",
              icon: <ThumbUpSharp />,
              style: {
                backgroundColor: "#1565C0",
                color: "white",
                borderRadius: "24px",
                padding: "10px 20px",
                fontSize: "16px",
                minWidth: "150px",
                marginRight: "20px",
                flex: "auto",
                maxWidth: "250px",
              },
            },
          ]}
        />
      ),
      waitAction: true,
      trigger: "getting-started",
    },

    {
      id: "message-redirect-to-portal",
      asMessage: true,
      inputAttributes: {
        url: () => {
          return self.crmPortalUrl;
        },
        timeout: 3000,
        target: "_top",
        html: () => {
          return "Our records show that this address is active in our database... Now directing you to our customer portal where you can manage your account online.. (redirecting now...)";
        },
      },
      component: <RedirectMessageComponent />,
      end: true,
    },
  ];
}
