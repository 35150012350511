import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";

// Styled Input component
const StyledInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

// Styles for the component
const useStyles = makeStyles((theme) => ({
  container: { width: "100%" },
  formControl: {
    width: "100%",
  },
}));

const Dropdown = ({
  selectOptions = [],
  id,
  triggerNextStep,
  setValue,
  placeholder,
  placeholderValue = "null",
}) => {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState(placeholderValue);

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    if (setValue) {
      setValue(event.target);
    }
    triggerNextStep({ value: event.target.value.label, sendUserMessage: true });
  };

  return (
    <div className={classes.container}>
      <FormControl className={classes.formControl}>
        <Select
          labelId={`${id}-label`}
          id={id}
          value={selectedValue}
          onChange={handleSelectChange}
          input={<StyledInput />}
        >
          <MenuItem key={`${id}-value-null`} value={placeholderValue}>
            {placeholder || "Please select an option"}
          </MenuItem>
          {selectOptions.map((option) => (
            <MenuItem key={option.value} value={option}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default Dropdown;
