import React from "react";

const ServiceBotHeader = ({ logo, tagLine }) => {
  const headerStyle = {
    borderBottomWidth: 1,
    // borderBottomStyle: 'solid',
    // boxShadow: '0 0 12px rgba(0,0,0,.03)',
    height: 100,
    minHeight: 100,
    transition: "border-color .75s,background-color .75s",
    flex: 1,
    justifyContent: "center",
    alignContent: "center",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    fontFamily: `'Open Sans', sans-serif`,
    textAlign: "center",
  };

  return (
    <div style={headerStyle}>
      <img
        alt="logo"
        style={{ marginTop: 15, maxWidth: 300, margin: "auto" }}
        src={logo}
      />
      <span style={{ position: "relative", fontWeight: "bold" }}>
        {tagLine}
      </span>
    </div>
  );
};

export default ServiceBotHeader;
