import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";

export function NoInputComponent({
  triggerNextStep,
  dispatchMessage,
  message,
  asHTML = false,
  showLoading = false,
  value,
  previousStep,
  disappearAfter = false,
}) {
  const [visible, setVisible] = useState(true);
  useEffect(() => {
    async function fn() {
      if (dispatchMessage) {
        await dispatchMessage(previousStep);
      }
      if (disappearAfter) {
        setVisible(false);
      }
      triggerNextStep({ value: "", sendUserMessage: false });
    }
    fn();
  }, [dispatchMessage, triggerNextStep, previousStep]);
  return asHTML ? (
    visible && (
      <div
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          width: "80%",
        }}
      >
        <div
          style={{ marginRight: 10 }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
        {showLoading ? <CircularProgress style={{ marginTop: 10 }} /> : null}
      </div>
    )
  ) : (
    <div style={{ color: "#ccc", fontSize: 18, marginTop: 10 }}>
      {visible ? message : ""}
    </div>
  );
}
